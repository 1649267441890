<template>
  <b-card
    title=" "
    aria-hidden="true"
    class="mb-0"
  >
    <b-form @submit.prevent="$emit('processCurso', curso)">
      <!-- Sistema Info: Input Fields -->
      <b-form>
        <b-row>

          <!-- Field: Establecimientos -->
          <b-col
            v-if="establecimientosOptions.length > 1"
            cols="12"
            md="4"

          >
            <b-form-group
              label="Establecimiento"
              label-for="establecimiento"
            >
              <b-overlay :show="cargaEstablecimientos">
                <v-select
                  v-model="curso.idEstablecimiento"
                  placeholder="Selecciona el establecimiento..."
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="establecimientosOptions"
                  :state="!$v.curso.idEstablecimiento.$invalid"
                  @input="$v.curso.$touch"
                >
                  <!-- @input="$v.curso.$touch" -->
                  <template #option="{ title, insignia }">
                    <b-avatar
                      size="32"
                      :src="insignia"
                      :text="avatarText(title)"
                    />
                    <span> {{ title }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  v-if="$v.curso.$dirty"
                  id="idEstablecimientoInfo"
                >
                  El campo establecimiento
                  <span v-if="!$v.curso.idEstablecimiento.required">
                    es requerido.
                  </span>
                </b-form-invalid-feedback>
              </b-overlay>
            </b-form-group>
          </b-col>

          <!-- Field: TipoEnseñanza -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Tipo Enseñanza"
              label-for="tipoEnseñanza"
            >
              <b-overlay>
                <v-select
                  v-model="curso.idNivel"
                  placeholder="Selecciona el tipo enseñanza..."
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="tipoEnseñanzasOptions"
                  :disabled="disabledTipoEnseñanza === 0"
                  :state="!$v.curso.idNivel.$invalid"
                  @input="changeTipoEnsenanza"
                />
                <b-form-invalid-feedback
                  v-if="$v.curso.$dirty"
                  id="idNivelInfo"
                >
                  El campo tipo enseñanza
                  <span v-if="!$v.curso.idNivel.required">
                    es requerido.
                  </span>
                </b-form-invalid-feedback>
              </b-overlay>
            </b-form-group>
          </b-col>

          <!-- Field: Grados -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Grado"
              label-for="grado"
            >
              <b-overlay :show="cargaGrados">
                <v-select
                  v-model="curso.idGrado"
                  placeholder="Selecciona el grado..."
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="gradosOptions"
                  :disabled="disabledGrado === 0"
                  :state="!$v.curso.idGrado.$invalid"
                  @input="$v.curso.$touch"
                />
                <b-form-invalid-feedback
                  v-if="$v.curso.$dirty"
                  id="gradoInfo"
                >
                  El campo grado
                  <span v-if="!$v.curso.idGrado.required">
                    es requerido.
                  </span>
                </b-form-invalid-feedback>
              </b-overlay>
            </b-form-group>
          </b-col>

          <!-- Field: Cantidad -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Cantidad"
              label-for="cantidad"
            >
              <b-form-input
                id="cantidad"
                v-model="curso.cantidad"
                placeholder="Ingresa la cantidad"
                :state="curso.cantidad
                  ? $v.curso.cantidad.$invalid == true
                    ? false
                    : null
                  : null"
                @input="$v.curso.$touch"
              />
              <b-form-invalid-feedback
                v-if="$v.curso.$dirty"
                id="cantidadInfo"
              >
                El campo cantidad
                <span v-if="!$v.curso.cantidad.required">
                  es requerido.
                </span>
              </b-form-invalid-feedback>

            </b-form-group>
          </b-col>

        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <btnSubmit
        variant="primary"
        :btnText="cursoSubmit"
        @processBtn="submitOption"
      />

    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BAvatar, BFormInvalidFeedback, BFormInput,
  BCard, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

import btnSubmit from '../../components/Form/btnSubmit.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BAvatar,
    BFormInvalidFeedback,
    BFormInput,
    BCard,
    BOverlay,

    vSelect,
    btnSubmit,
  },
  mixins: [validationMixin],
  props: {
    curso: {
      type: Object,
      required: true,
    },
    cursoSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Sistema',
    },
  },
  data() {
    return {
      dir: 'ltr',
      cargaEstablecimientos: true,
      cargaTipoEnseñanzas: true,
      cargaGrados: false,
      establecimientosOptions: [],
      // periodosOptions: [],
      tipoEnseñanzasOptions: [],
      gradosOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      getEstablecimientosActivos: 'establecimientos/getEstablecimientosActivos',
      getTipoEnseñanzas: 'tipoEnseñanza/getTipoEnseñanzas',
      getGrados: 'grados/getGrados',
    }),
    // disabledPeriodo() {
    //   return this.periodosOptions.length
    // },
    disabledTipoEnseñanza() {
      return this.curso.idEstablecimiento.length
    },
    disabledGrado() {
      return this.gradosOptions.length
    },
  },
  validations: {
    curso: {
      idEstablecimiento: {
        required,
      },
      cantidad: {
        required,
      },
      idNivel: {
        // required,
      },
      idGrado: {
        required,
      },
    },
  },
  watch: {
    getEstablecimientosActivos() {
      this.setEstablecimientosActivosOptions(this.getEstablecimientosActivos)
    },
    getTipoEnseñanzas() {
      this.setTipoEnseñanzasOptions(this.getTipoEnseñanzas)
    },
  },
  mounted() {
    this.fetchEstablecimientosActivos()
    this.fetchTipoEnseñanza()
  },
  methods: {
    ...mapActions({
      fetchEstablecimientosActivos: 'establecimientos/fetchEstablecimientosActivos',
      // fetchPeriodos: 'periodos/fetchPeriodos',
      fetchTipoEnseñanza: 'tipoEnseñanza/fetchTipoEnseñanza',
      fetchGrados: 'grados/fetchGrados',
      // fetchDocentesActivos: 'usuarios/fetchDocentesActivos',
    }),
    submitOption() {
      this.$v.curso.$touch()
      if (!this.$v.curso.$invalid) {
        this.$emit('processCurso', this.curso)
      }
    },
    setEstablecimientosActivosOptions(establecimientosActivos) {
      // solo si es super admin y admin daem muestra todos los establecimientos
      establecimientosActivos.forEach(establecimientoActivo => {
        this.establecimientosOptions.push({
          id: establecimientoActivo.id,
          title: establecimientoActivo.nombre,
          insignia: establecimientoActivo.insignia,
        })
      })

      if (this.establecimientosOptions.length === 1) {
        this.curso.idEstablecimiento = {
          id: this.establecimientosOptions[0].id,
          title: this.establecimientosOptions[0].nombre,
          insignia: this.establecimientosOptions[0].insignia,
        }
      }
      this.cargaEstablecimientos = false
    },
    setTipoEnseñanzasOptions(tipoEnseñanzas) {
      this.tipoEnseñanzasOptions = []
      tipoEnseñanzas.forEach(tipoEnseñanza => {
        const title = `${tipoEnseñanza.idNivel} - ${tipoEnseñanza.nombre}`
        this.tipoEnseñanzasOptions.push({
          id: tipoEnseñanza.id,
          idNivel: tipoEnseñanza.idNivel,
          title,
        })
      })
      this.cargaTipoEnseñanzas = false
    },
    changeTipoEnsenanza() {
      this.resetGradosOptions()
      const { idNivel } = this.curso.idNivel
      this.cargaGrados = true
      this.fetchGrados().then(() => {
        const grados = this.getGrados.filter(gg => gg.idNivel === idNivel)
        this.setGradosOptions(grados)
      })
    },
    setGradosOptions(grados) {
      this.resetGradosOptions()
      grados.forEach(grado => {
        const title = `${grado.nombre}`
        this.gradosOptions.push({
          id: grado.id,
          idGrado: grado.idGrado,
          title,
        })
      })
      this.cargaGrados = false
    },
    resetGradosOptions() {
      this.gradosOptions = []
    },
    resetAll() {
      this.curso.idGrado = null
      this.curso.idNivel = null
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
