<template>
  <cursoForm
    :curso="cursoData"
    cursoSubmit="Crear Curso"
    @processCurso="agregarCurso"
  />
</template>

<script>

import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import cursoForm from './components/CursosForm.vue'

export default {
  components: {
    cursoForm,
  },
  data() {
    return {
      cursoData: {
        cantidad: 1,
        idGrado: '',
        idEstablecimiento: '',
      },
    }
  },
  methods: {
    ...mapActions({ createCurso: 'cursos/addCursos' }),
    agregarCurso(curso) {
      this.createCurso(curso).then(() => {
        const errorCursos = store.state.cursos
        const text = curso.cantidad > 1
          ? `Los cursos de "${curso.idGrado.title}" fueron creados con éxito!`
          : `El curso "${curso.idGrado.title}" fue creado con éxito!`
        // const errorMessage = errorCursos.errorMessage.errors
        if (!errorCursos.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Curso creado con éxito 👍',
              icon: 'CheckIcon',
              text,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
            position: 'bottom-right',
          })
          this.$router.replace({
            name: 'cursos',
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
